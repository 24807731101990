<script>
import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      aduana: "PIEDRAS NEGRAS, COAHUILA.",
      banco: "BANAMEX",
      documento: "4000178",
      no_operacion: "400020703223899450",
      fecha_1: "2024-05-20 02:38:44 PM",
      aduana_informacion: "270 PIEDRAS NEGRAS, COAHUILA.",
      patente: "3074",
      banco_informacion: "BANAMEX",
      numero_de_operacion: "0000000733934",
      importe: "21612.00",
      fecha_y_hora_de_pago: this.fecha_1,
      linea_de_captura: "276967A3XEH052387308",
      responseUrl: "", // Para almacenar la URL de respuesta del servidor
      qrData: "", // Para almacenar los datos del QR
      pedimiento_domain: process.env.VUE_APP_PEDIMIENTO_DOMAIN,
    };
  },
  watch: {
  },
  computed: {
    qrCodeUrl() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.responseUrl)}`;
    },
    formValido() {
  return (
    this.aduana !== '' &&
    this.banco !== '' &&
    this.documento !== '' &&
    this.no_operacion !== '' &&
    this.fecha_1 !== '' &&
    this.aduana_informacion !== '' &&
    this.patente !== '' &&
    this.banco_informacion !== '' &&
    this.importe !== '' &&
    this.linea_de_captura !== '' 
  );
},
  },
  
  methods: {
    async crearCarpeta() { this.cargando = true; // Iniciar la carga
  
  // Aquí puedes realizar la solicitud POST a tu endpoint
  try {
    const response = await fetch(`https://${this.pedimiento_domain}/SOIANET/connection/new_folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        aduana: this.aduana.toUpperCase(),
        banco: this.banco.toUpperCase(),
        documento: this.documento.toUpperCase(),
        no_operacion: this.no_operacion.toUpperCase(),
        fecha_1: this.fecha_1.toUpperCase(),
        aduana_informacion: this.aduana_informacion.toUpperCase(),
        patente: this.patente.toUpperCase(),
        banco_informacion: this.banco_informacion.toUpperCase(),
        numero_de_operacion: this.numero_de_operacion.toUpperCase(),
        importe: this.importe,
        fecha_y_hora_de_pago: this.fecha_1,
        linea_de_captura: this.linea_de_captura.toUpperCase(),
      }),
    });
    
    const data = await response.json();
    this.responseUrl = data.url;
    
  } catch (error) {
    console.error('Error al enviar la solicitud:', error);
  } finally {
    this.cargando = false; // Finalizar la carga
  }
    },
    downloadQR() {
      const link = document.createElement("a");
      link.href = this.qrCodeUrl;
      link.download = "qrCode.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
   
  },
};
</script>

<template>
  <Layout>
    <div class="col-xl-12">
      <h5 class="title-home">Pedimiento<span class="dot-home">.</span></h5>
      <!-- Formulario -->
      <form @submit.prevent="crearCarpeta">
        <!-- Primer conjunto de campos -->
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="folioFiscal">Aduana:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="folioFiscal" placeholder="" v-model="aduana"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="rfcEmisor">Banco:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="rfcEmisor" placeholder="" v-model="banco"/>
        </div>
        <div style="float:left; width:32%; padding-bottom: 20px;">
          <label for="rfcReceptor">Documento:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="rfcReceptor" placeholder="" v-model="documento"/>
        </div>
        <div style="clear:both;"></div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="razon_social_receptor">Número operación:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="razon_social_receptor" placeholder="" v-model="no_operacion"/>
        </div>
      
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="fecha_certificacion_sat">Información aduana:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="fecha_certificacion_sat" placeholder="" v-model="aduana_informacion"/>
        </div>

        <div style="float:left; width:32%; padding-bottom: 20px;">
          <label for="fechaExpedicion">Fecha:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="fechaExpedicion" placeholder="" v-model="fecha_1"/>
        </div>
       
        <div style="clear:both;"></div>
        
        <!-- Tercer conjunto de campos -->
     
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="pac_que_certifico">Patente:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="pac_que_certifico" placeholder="" v-model="patente"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="total_del_cfdi">Banco:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="total_del_cfdi" v-model="banco_informacion" />
                </div>
                <div style="float:left; width:32%; -bottom: 20px;">
          <label for="efecto_del_comprobante">Numero de operación:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="efecto_del_comprobante" placeholder="" v-model="numero_de_operacion"/>
        </div>
        
        <!-- Cuarto conjunto de campos -->
      
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="efecto_del_comprobante">Importe:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="efecto_del_comprobante" placeholder="" v-model="importe"/>
</div>

        <div style="float:left; width:32%; margin-right: 2%;  padding-bottom: 20px;">
          <label for="efecto_del_comprobante">Estado linea de captura:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="efecto_del_comprobante" placeholder="" v-model="linea_de_captura"/>
</div>

<div style="float:left; width:32%; margin-right: 2%;  padding-bottom: 20px;">
  <button v-if="!cargando" :disabled="!formValido || cargando" type="submit" class="btn button-client-form btn-primary">
          <span v-if="!cargando">CREAR NUEVO</span>
          <span v-else>
            <i class="fa fa-spinner fa-spin"></i> Cargando...
          </span>
        </button>  
</div>        <!-- Botón para enviar el formulario -->
           </form>
      
      <!-- Mostrar la URL de respuesta y el QR -->
      <div v-if="responseUrl">
        <p style="padding-top:15px;">URL Factura Electrónica:<br> <a :href="responseUrl" target="_blank">{{ responseUrl }}</a></p>
        <img :src="qrCodeUrl" alt="QR Code" />
        <br><button style="margin-top:15px;" @click="downloadQR" class="btn btn-primary">Descargar QR</button>
      </div>
    </div>
  </Layout>
</template>
<style>
button.btn.button-client-form.btn-primary {
    margin-top: 30px;
}
</style>
